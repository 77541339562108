import React from "react";

function Individual() {

    return(
        <>
        <div className="mx-md-5">
            <h2 className="subtitle text-center">Je suis un particulier</h2>
            <div data-aos="fade-left" className="post left">
                <img src="./ressources/pictures/pic-8.jpg" loading="lazy" className="shadow-event"/>
                <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                    Repas de famille, Anniversaire, baptême, communions, rebond de mariage…
                </p>
            </div>
            <div data-aos="fade-right" className="post right">
                <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                    Se mettre d’accord pour un repas de famille relève souvent du casse-tête.
                    Tatie Jeanine est végétarienne et votre cousin Jean-Marc est allergique au gluten.
                    Proposer un plat unique semble compliqué…
                    Et créer un plat pour chacun, c’est carrément impossible !
                </p>
            </div>
            <div data-aos="fade-left" className="post left">
                <img src="./ressources/pictures/pic-9.jpg" loading="lazy" className="shadow-event"/>
                <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                    Les menus galettes et crêpes sont le bon compromis : 
                    tout le monde mange la même base et chacun peut personnaliser sa garniture 
                    selon ses préférences.
                </p>    
            </div>
            <div data-aos="fade-right" className="post right">
                <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                    Les galettes et les crêpes remportent toujours un franc 
                    succès à l’occasion des repas d’anniversaire. 
                    Ce concept de cuisine en direct devant les invités séduit 
                    par son aspect convivial. 
                    Les invités se rapprochent du crêpier pour demander leurs galettes puis leurs crêpes et choisissent leurs ingrédients préférés.
                </p>
            </div>
        </div>
        </>
    );

}

export default Individual;