import React from "react";

function Association() {

    return(
        <>
        <div className="mx-md-5">
                <h2 className="subtitle text-center">Je suis une Association</h2>
                <div data-aos="fade-left" className="post left">
                    <img src="./ressources/pictures/pic-10.jpg" loading="lazy" className="shadow-event"/>
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Vous organisez l’assemblée générale de votre association 
                        et vous cherchez une solution pour restaurer les adhérents à la fin de la réunion ?
                        Un repas de galettes et de crêpes vous permettra de satisfaire l’ensemble des convives.
                        Chacun pourra choisir les ingrédients qu’il souhaite.
                        Vous vous assurez de faire plaisir à tous.
                    </p>
                </div>
                <div data-aos="fade-right" className="post right">
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Avec l’été arrive la saison des kermesses et des fêtes d’école, 
                        des événements familiaux souvent organisés en extérieur.
                        Il est souvent compliqué de trouver un menu qui ravira les petits autant que les grands. Avec les galettes et crêpes , vous êtes sûr de faire plaisir à tous.
                    </p>
                    <img src="./ressources/pictures/pic-11.jpg" loading="lazy" className="shadow-event"/>
                </div>
        </div>
        </>
    );

}

export default Association;