import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

interface formContact {
    lastname : string,
    firstname : string,
    email : string,
    phone : string,
    type: string,
    date : string,
    numbers : number,
    address : string,
    know : string,
    honey: string,
    action: string
}

function Contact(props : any) {

    const newFormContact : formContact = {
        lastname : '',
        firstname : '',
        email : '',
        phone : '',
        type : '',
        date : '',
        numbers : 0,
        address : '',
        know : '',
        honey : '',
        action: 'contact'
    }

    const [formData, setFormData] = useState<formContact>(newFormContact);
    const [isCheck, setIsCheck] = useState(false);

    function handleCheck() {
        setIsCheck(isCheck ? false : true);
      }
    

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if(formData.honey === '') {
            let data = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                data.append(key, value);
            });
            let response = await axios.post("https://crepe-event.fr/scripts/contact.php", data, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(response => {
                response.data === false ? Swal.fire('Une erreur s\'est produite.','', 'error') : Swal.fire('Message envoyé !','', 'success');
                setFormData(newFormContact);
                setIsCheck(false);
            }).catch(error => {
                if (error.response && error.response.status === 500) { 
                    Swal.fire('Champ(s) incomplet(s)','', 'error');
                } else {
                    Swal.fire('Une erreur s\'est produite.','', 'error');
                }
            });
        } else {
            Swal.fire('Une erreur s\'est produite.','', 'error');
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { name, value } = e.target;
        setFormData((Prev) => ({
            ...Prev,
            [name]: value,
        }));
    }

    return(
        <Form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="lastname">Prénom</label>
                <input type="text" name="lastname" required={true} className="form-control" value={formData.lastname} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="firstname">Nom</label>
                <input type="text" name="firstname" required={true} className="form-control" value={formData.firstname} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="email">Email</label>
                <input type="mail" name="email" required={true} className="form-control" value={formData.email} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="phone">Téléphone</label>
                <input type="tel" name="phone" required={true} className="form-control" value={formData.phone} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="type">Type d'événement</label>
                <input type="text" name="type" className="form-control" value={formData.type} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="date">Date de votre événement</label>
                <input type="date"name="date" className="form-control" value={formData.date} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="numbers">Nombre d'invités</label>
                <input type="number" name="numbers" className="form-control" value={formData.numbers} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="address">Lieu de l'événement</label>
                <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="know">Comment avez vous connu Crêpe Event ?</label>
                <input type="text" name="know" className="form-control" value={formData.know} onChange={handleChange}/>
            </div>
            <div className="mb-3 d-none">
                <label htmlFor="honey">Ne pas remplir ce champ</label>
                <input type="text" name="honey" className="form-control" onChange={handleChange}/>
            </div>
            <div className="mb-3 form-check">
                <input type="checkbox" name="tos" required={true} checked={isCheck} onChange={handleCheck} className="form-check-input" />
                <label className="form-check-label" htmlFor="tos">J'accepte d'être recontacté et ai pris connaissance des <Link className="link underline" to={{pathname: '/mentions-légales', hash: '#donneesPersonnelles'}}>C.G.V</Link></label>
              </div>
            <Button variant="primary" type="submit">
                Envoyer
            </Button>
        </Form>
    );
}

export default Contact;