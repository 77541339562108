import React from "react";
import Slider from 'react-infinite-logo-slider';

function SliderBrands() {

    const brands = [['./ressources/logos/logo_1.png', 'https://www.supercasino.fr/'], 
        ['./ressources/logos/logo_2.png', 'https://www.tourcoing.fr/'],
        ['./ressources/logos/logo_3.png', 'https://www.bousbecque.fr/'],
        ['./ressources/logos/logo_4.png', 'https://www.wervicq-sud.com/'],
        ['./ressources/logos/logo_5.png', 'https://www.facebook.com/NordiquesFloorballClub/?locale=fr_FR'],
        ['./ressources/logos/logo_6.webp', 'https://www.salondumariageyesido.com/'],
        ['./ressources/logos/logo_7.png', 'https://techshop-lille.fr/'],
        ['./ressources/logos/logo_8.png', 'https://www.rugby-tourcoing.com/'],
        ['./ressources/logos/logo_9.jpeg', 'https://www.mouscron.be/fr/ma-ville/enseignement/maternel-primaire/communal/ecole-communale-raymond-devos'],
        ['./ressources/logos/logo_10.png', 'https://www.mouvaux.fr/'],
        ['./ressources/logos/logo_11.jpg', 'https://www.halluin.fr/'],
        ['./ressources/logos/logo_12.png', 'https://www.santes.fr/'],
    ];
    
    return(
        <div className="bg-brands p-2 mt-5 mt-lg-3 rounded">
            <Slider
                width="220px"
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                blurBoderColor={'#fff'}
            >
                {brands.map((brand : string[]) => {
                    return(
                        <Slider.Slide>
                        <a href={brand[1]} target="_blank" rel="noreferrer"><img src={brand[0]} alt="logo" className='display-brand' /></a>
                        </Slider.Slide> 
                    )
                })}
            </Slider>
        </div>
    );

}

export default SliderBrands;