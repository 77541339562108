import React from 'react';
import './App.scss';
import {Routes, Route, Link, HashRouter } from "react-router-dom";
import AppNavbar from './Components/Navbar.tsx';
import WhoAmI from "./Pages/WhoAmI.tsx";
import LandingPage from "./Pages/LandingPage.tsx";
import Individual from "./Pages/Individual.tsx";
import Professional from "./Pages/Professional.tsx";
import Association from "./Pages/Association.tsx";
import LegalsMentions from "./Pages/LegalsMentions.tsx";
import ScrollToAnchor from './ScrollToAnchor.tsx';

function App() {
  return (
    <HashRouter>
      <AppNavbar />
      <ScrollToAnchor />
      <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<LandingPage />} />
          <Route path="/mentions-légales" element={<LegalsMentions />} />
          <Route path="/qui-suis-je" element={<WhoAmI />} />
          <Route path="/particulier" element={<Individual />} />
          <Route path="/professionnel" element={<Professional />} />
          <Route path="/association" element={<Association />} />
      </Routes>
    </HashRouter>
  );
}
export default App;
