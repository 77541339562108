import React from "react";

function Professional() {

    return(
        <>
        <div className="mx-md-5">
                <h2 className="subtitle text-center">Je suis un professionnel</h2>
                <div data-aos="fade-left" className="post left">
                    <img src="./ressources/pictures/pic-5.jpg" loading="lazy" className="shadow-event"/>
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Vous organisez un séminaire, un repas entre collègues ou un arbre de Noël ?
                    </p>
                </div>
                <div data-aos="fade-right" className="post right">
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Vous souhaitez fêter le départ en retraite de votre meilleur collègue ou vous souhaitez régaler vos clients et proposer autre chose que des petits fours ?
                    </p>
                    <img src="./ressources/pictures/pic-6.jpg" loading="lazy" className="shadow-event"/>
                </div>
                <div data-aos="fade-left" className="post left">
                    <img src="./ressources/pictures/pic-7.jpg" loading="lazy" className="shadow-event"/>
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Les crêpes & galettes s’invitent de plus en plus lors d'événements professionnels.
                        Les garnitures simples et variées ainsi que le format ludique 
                        sauront séduire vos convives.
                    </p>
                </div>
        </div>
        </>
    );

}

export default Professional;