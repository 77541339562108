import React from "react";
import { NavLink } from "react-router-dom";
import SliderBrands from '../Components/SliderBrands.tsx';

function LandingPage() {

    return(
        <>
        <div className="text-center full-page">
            <img className="logo" src="./ressources/logo2.png" alt="Logo" />
            <div></div>
            <h1 className="title">Crepe Event</h1>
            <h2 className="annonce shadow-event">
                Votre crêpier évenementiel
            </h2>
            <NavLink className="letsgo shadow-event" to="/qui-suis-je">Découvrez moi !</NavLink>
            <SliderBrands />
        </div>
        {/* <div className="d-flex flex-wrap justify-content-center align-items-center bg-light w-lg-50 mx-auto shadow mb-3">
            <h2 className="text-primary fw-bold py-3">Ils m'ont fait confiance !!!</h2>
            <div className="d-flex image-display">
                <img src="./ressources/logos/logo_1.png" />
                <img src="./ressources/logos/logo_2.png" />
                <img src="./ressources/logos/logo_3.png" />
                <img src="./ressources/logos/logo_4.png" />
                <img src="./ressources/logos/logo_5.png" />
                <img src="./ressources/logos/logo_6.webp" />
                <img src="./ressources/logos/logo_7.png" />
                <img src="./ressources/logos/logo_8.svg" />
                <img src="./ressources/logos/logo_9.png" />
                <img src="./ressources/logos/logo_10.jpeg" />
            </div>
        </div> */}
        
        </>
    )
}

export default LandingPage;