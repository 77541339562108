import React from "react"

function LegalsMentions() {

    return(
        <>
        <div className="mx-md-5">
                <h2 className="subtitle text-center">Mentions Legales</h2>
                <div>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site www.crepe-event.fr , ci-après le "Site", les présentes mentions légales.<br />
                        La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 1. L'Editeur</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    L'édition du Site est assurée par CREPE EVENT Auto entrepreneur au capital de 0 euros,
                    immatriculée au Registre du Commerce et des Sociétés de LILLE METROPOLE A 913 692 182 sous
                    le numéro 913692182 dont le siège social est situé au 64 RUE HOUCHARD 59200 TOURCOING,
                    Numéro de téléphone 0664851612,<br />
                    Adresse e-mail : contact@crepe-event.fr.<br />
                    N° de TVA intracommunautaire : FR21913692182<br />
                    Le Directeur de la publication est Johan Libert, ci-après l'"Editeur".
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 2. l'Hebergeur</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    L'hébergeur du Site est la société O2Switch, dont le siège social est situé au Chem. des Pardiaux, 3000 Clermont-Ferrand<br />
                    Téléphone : 0444446040<br />
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 3. Webmaster</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Site web développé par <a target="_blank" href="https://www.devexploris.com" className="link-ln">Exploris</a>
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 4. Acces au site</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption
                    programmée ou non et pouvant découlant d’une nécessité de maintenance.<br />
                    En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
                    Tous les informations proposées sur le Site sont données à titre indicatif, sont non exhaustives, et sont susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                    </p>
                </div>
                <div id="donneesPersonnelles">
                    <h3 className="subtitle ln">Article 5. RGPD - Collecte des donnees</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect
                    de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
                    et aux libertés.<br />
                    Aucune information de l'utilisateur n'est publiée sans son consentement explicite.<br />
                    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit
                    d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur
                    exerce ce droit : <br />
                    - par mail à l'adresse email contact@crepe-event.fr<br />
                    </p>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Les informations transmisent par l'utilisateur sont utilisées uniquement à des fins de réponse au formulaire de contact par l'éditeur. Il est informé par une case à cocher dans le formulaire.<br />
                    Les informations personnelles demandées sont les suivantes :<br />
                    - Prénom <br />
                    - Nom <br />
                    - Email <br />
                    - Téléphone <br />
                    Elles sont archivées pendant deux ans à compter de la date de réception.<br />
                    Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 6. Limite de responsabilité</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Le Site utilise la technologie javascript.
                    Le Site ne saurait être tenu responsable des erreurs typographiques ou inexactitudes apparaissant sur le service, ou de quelque dommage subi résultant de son utilisation. L’utilisateur est responsable de son équipement et de son utilisation.
                    L’utilisateur s’engage à accéder à celui-ci en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mise à jour.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 7. Propriété intellectuelle</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Le proprietaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels…
                    Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
                    Toute exploitation non autorisée du site ou de l’un quelconque de ces éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 8. Droit applicable et attribution de juridiction</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Tout litige en relation avec l’utilisation du Site est soumis au droit français. L’utilisateur ainsi que l'Editeur acceptent de se soumettre à la compétence exclusive des tribunaux Français en cas de litige.
                    </p>
                </div>
                <h2 className="subtitle text-center">C.G.V</h2>
                <div>
                    <h3 className="subtitle ln">Article 1. Clause de non responsabilite</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    En cas d'intempéries imprévues, de problèmes d'électricité ou de tout autre événement indépendant de notre volonté susceptible de compromettre la prestation de nos services de crêpier événementiel, nous déclinons toute responsabilité pour tout préjudice, perte ou désagrément subi par les clients. Nous nous réservons le droit d'adapter notre prestation en conséquence et nous nous efforcerons de trouver des solutions alternatives dans la mesure du possible. En engageant nos services, les clients reconnaissent comprendre et accepter cette clause de non-responsabilité.<br />
                    Afin que la prestation de Crêpe Event puisse être assurée, l'électricité doit être accessible.( 2x 3600 W sur 2 circuits différents )<br />
                    En cas de changement de l'un de ces éléments essentiels (lieu, date, horaire, nombre de personnes) à l'initiative du client, Crêpe Event pourra annuler sa prestation.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 2. Reservation definitives</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Les réservations sont considérées comme définitives à partir du moment où le client a validé  par écrit  en indiquant le lieu précis de la prestation, la date et l'horaire. 
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 3. Accompte</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Dans le cas d'une prestation “clé en mains”  un acompte de 30 % pour une réservation  "offrir à vos convives", dans ce cas le nombre de personnes sera également mentionné et validé par écrit.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 4. Annulation</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Toute annulation à l'initiative du client entraînera la perte de l'acompte versé.<br />
                    Toute annulation ou changement à l'initiative de Crêpe Event entraînera le remboursement intégral de l'acompte au client.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 5. Validite du devis</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Le devis est valable 1 mois, passé ce délai, il peut être modifié, un nouveau devis sera alors effectué en cas de modification.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 6. Supplements</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Tout supplément (marchandise, boisson, horaire,) lors de la prestation, non compris dans le devis, sera facturé.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 7. Difference devis - prestation</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    La facturation sera établie en fonction du devis à la réservation et ce même si le nombre de personnes est inférieur au devis.<br />
                    Dans le cas d'un nombre de personnes supérieur au devis, la facturation sera établie en fonction du nombre de personnes servies sur la base du tarif du menu réservé. <br />
                    Crêpe Event ne pourra être tenu responsable de la qualité de son service en cas de rajout de personnes supplémentaires.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 8. Visite preliminaire</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Une visite du lieu de la réception pourra éventuellement être établie afin de régler la mise au point de la prestation de Crêpe Event.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 9. Passage interdit</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Pour prévenir les risques d'accident, le passage derrière le plan de travail est strictement interdit sauf autorisation en cas d'animation crêpes.
                    </p>
                </div>
                <div>
                    <h3 className="subtitle ln">Article 10. Reclammation</h3>
                    <p className="text-white fw-bold text-center p-2 fs-3 text-shadow">
                    Toute réclamation concernant la prestation fournie devra être effectuée par écrit dans un délai de 48 heures après la réception. Si aucun accord amiable n'est possible, la compétence des tribunaux sera alors sollicitée.
                    </p>
                </div>
        </div>
        </>
    );

}

export default LegalsMentions;