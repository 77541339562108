import React from "react";
import { NavLink } from "react-router-dom";

function WhoAmI() {
    
    return(
        <div className="mx-md-5">
                <h2 className="subtitle text-center">Qui suis-je ?</h2>
                <div data-aos="fade-left" className="post left">
                    <img src="./ressources/pictures/pic-1.jpg" loading="lazy" className="shadow-event" alt="Johan"/>
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Après une grande expérience d’une dizaine d’années dans la restauration et l'événementiel, je me suis reconverti professionnellement en 2016 pour des raisons familiales.
                    </p>
                </div>
                <div data-aos="fade-right" className="post right">
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        J’ai toujours eu l’envie d’entreprendre, l’ambiance et la disparité lors des différentes réceptions m’ont poussé à
                        créer Crêpe Event en mai 2022
                    </p>
                    <img src="./ressources/pictures/pic-3.jpg" loading="lazy" className="shadow-event"/>
                </div>
                <div data-aos="fade-left" className="post left">
                    <img src="./ressources/pictures/pic-2.jpg" loading="lazy" className="shadow-event"/>
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Je travaille en grande majorité avec des produits frais et régionaux. 
                        Pas de poudre de Perlimpinpin mélangées à de l’eau !
                        Les pâtes sont élaborées avec des produits bruts la veille de votre événement.
                    </p>
                </div>
                <div data-aos="fade-right" className="post right">
                    <p className="text-danger fw-bold text-center p-2 fs-3 text-shadow">
                        Je dispose d’un stand avec un barnum aux normes anti-feu.
                        Assurance R.C PRO et formation HACCP à jour.
                        2 prises électriques 220 V 16A (sur 2 disjoncteurs différents) sont nécessaires.
                    </p>
                    <img src="./ressources/pictures/pic-4.jpeg" loading="lazy" className="shadow-event" />
                </div>
        </div>
    );

}

export default WhoAmI;