import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown, NavItem, Modal } from 'react-bootstrap';
import Contact from "./Contact.tsx";

function AppNavbar() {

    const location = useLocation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(location.pathname === "/contact") {
            setShow(true);
        }
    }, [location])

    function handleClose() {
        setShow(false);
    }


    return(
        <>
        <Navbar expand="lg" className="navbar-light d-flex flex-lg-column justify-content-around w-100 fs-3">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav text">
                <Nav className="me-auto d-flex flex-lg-row flex-column justify-content-around align-items-center my-lg-0 my-3">
                    <div className="mx-5 d-none d-lg-flex"> 
                        <NavItem onClick={() => {setShow(true)}}><img src="./ressources/contact.png" alt="contact" className="icons mx-2 normal"  title="Accéder à la rubrique contact" style={{cursor: "pointer"}}/></NavItem>
                        <NavItem><a href="https://wa.me/33664851612"><img src="./ressources/whatsapp.png" alt="whatsapp" className="icons mx-2 reverse" title="Envoyez moi un message" /></a></NavItem>
                    </div>
                    <NavItem><NavLink className="text-light fw-bold mx-2 text-decoration-none" to="/qui-suis-je">Qui suis-je ?</NavLink></NavItem>
                    <NavDropdown title="Je suis ..." className="text-light fw-bold mx-2">
                        <NavDropdown.Item><NavLink to="/particulier" className="text-white text-decoration-none fw-bold fs-5">Un particulier ?</NavLink></NavDropdown.Item>
                        <NavDropdown.Item><NavLink to="/professionnel" className="text-white text-decoration-none fw-bold fs-5">Un professionnel ?</NavLink></NavDropdown.Item>
                        <NavDropdown.Item><NavLink to="/association" className="text-white text-decoration-none fw-bold fs-5">Une association ?</NavLink></NavDropdown.Item>
                    </NavDropdown>
                    <NavItem title="Mentions légales"><NavLink className="text-light fw-bold mx-2 text-decoration-none" to="/mentions-légales"><img src="./ressources/mentions.png" alt="mentions légales" className="icons m-2" /></NavLink></NavItem>
                    <div className="d-flex mx-5">
                        <NavItem><a href="https://www.facebook.com/profile.php?id=100083459255785" target="_blank"><img src="./ressources/facebook.png" alt="facebook" className="icons mx-2 normal" /></a></NavItem>
                        <NavItem><a href="https://www.facebook.com/profile.php?id=100083459255785" target="_blank"><img src="./ressources/instagram.png" alt="instagram" className="icons mx-2 reverse" /></a></NavItem>
                        <NavItem onClick={() => {setShow(true)}} ><img src="./ressources/contact.png" alt="contact" className="icons mx-2 normal d-lg-none" title="Accéder à la rubrique contact" style={{cursor: "pointer"}}/></NavItem>
                        <NavItem><a href="https://wa.me/33664851612"><img src="./ressources/whatsapp.png" alt="whatsapp" className="icons mx-2 reverse d-lg-none" title="Envoyez moi un message" /></a></NavItem>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        {show && 
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Me contacter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Contact />
                </Modal.Body>
            </Modal>
                }
        </>
    )
}

export default AppNavbar;